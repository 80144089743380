// Generated by Framer (353ff71)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["RGUowbiCQ", "j8kttpo5R"];

const serializationHash = "framer-8yfqF"

const variantClassNames = {j8kttpo5R: "framer-v-1ad4123", RGUowbiCQ: "framer-v-1gnsavj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "RGUowbiCQ", "Variant 2": "j8kttpo5R"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "RGUowbiCQ"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "RGUowbiCQ", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1gnsavj", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"RGUowbiCQ"} ref={refBinding} style={{background: "radial-gradient(50% 50% at 50% 50%, var(--token-6273e721-2def-45dd-827c-6ecf48c14ce3, rgb(218, 218, 218)) /* {\"name\":\"2025 Clay\"} */ 0%, rgba(217, 217, 217, 0) 100%)", opacity: 1, ...style}} variants={{j8kttpo5R: {opacity: 0}}} {...addPropertyOverrides({j8kttpo5R: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-8yfqF.framer-1rp3fvc, .framer-8yfqF .framer-1rp3fvc { display: block; }", ".framer-8yfqF.framer-1gnsavj { height: 22px; overflow: visible; position: relative; width: 22px; }", ".framer-8yfqF.framer-v-1ad4123.framer-1gnsavj { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 22px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 22
 * @framerIntrinsicWidth 22
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"j8kttpo5R":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerMLGXxT2S8: React.ComponentType<Props> = withCSS(Component, css, "framer-8yfqF") as typeof Component;
export default FramerMLGXxT2S8;

FramerMLGXxT2S8.displayName = "Radar finds";

FramerMLGXxT2S8.defaultProps = {height: 22, width: 22};

addPropertyControls(FramerMLGXxT2S8, {variant: {options: ["RGUowbiCQ", "j8kttpo5R"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerMLGXxT2S8, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})